import logoWallImage from "../images/logo-wall.png"
import logoWallVertical from "../images/logo-wall-mobile.png"
import React from "react"

const LogoWall = () => (
  <div>
    <img src={logoWallImage} width={1000} className="my-8 lg:my-12 hidden lg:block" />
    <img src={logoWallVertical} width={150} className="my-8 lg:my-12 lg:hidden" />
  </div>
)

export default LogoWall
