import React, { useState } from "react"

import SEOimage from "../images/next/twitter-image.png"

import importDataSVG from "../images/next/import-data.svg"
import rightAnswerSVG from "../images/next/right-answer.svg"
import crossRefPng from "../images/next/cross-reference-illu.png"
import summarizePng from "../images/next/summarise-illu.png"
import designPng from "../images/next/design-illu.png"
import sharingViewPng from "../images/next/sharing-view.png"

import Layout from "../components/layout"

import salesUseCase1 from "../images/next/sales/Connect.png"
import salesUseCase2 from "../images/next/sales/Answer, summarise, prioritise.png"
import salesUseCase3 from "../images/next/sales/Ship correctly.png"

import procurementUseCase1 from "../images/next/operations/Connect.png"
import procurementUseCase2 from "../images/next/operations/Answer, summarise, prioritise.png"
import procurementUseCase3 from "../images/next/operations/Ship correctly.png"

import hrUseCase1 from "../images/next/hr/Connect.png"
import hrUseCase2 from "../images/next/hr/Answer, summarise, prioritise.png"
import hrUseCase3 from "../images/next/hr/Ship correctly.png"

import foundersUseCase1 from "../images/next/founders/Connect.png"
import foundersUseCase2 from "../images/next/founders/Answer, summarise, prioritise.png"
import foundersUseCase3 from "../images/next/founders/Stay atop of your company.png"

import legalUseCase1 from "../images/next/legal/Connect.png"
import legalUseCase2 from "../images/next/legal/Answer, summarise, prioritise.png"
import legalUseCase3 from "../images/next/legal/Read legal docs faster.png"

import investorsUseCase1 from "../images/next/investors/Connect.png"
import investorsUseCase2 from "../images/next/investors/Answer, summarise, prioritise.png"
import investorsUseCase3 from "../images/next/investors/Spot opportunities and risks.png"

import researcherUseCase1 from "../images/next/researcher/Connect.png"
import researcherUseCase2 from "../images/next/researcher/Answer, summarise, prioritise.png"
import researcherUseCase3 from "../images/next/researcher/Collaborate effectively.png"

import * as Accordion from "@radix-ui/react-accordion"

import { CarouselProvider, Dot, Slider, Slide } from "pure-react-carousel"
import {
  IconCheck,
  IconChevronDown,
  IconDatabase,
  IconEyeOff,
  IconLock,
  IconRocket,
  IconTable,
} from "@tabler/icons"

import classNames from "classnames"

import SEO from "../components/seo"
import LogoWall from "../components/LogoWall"

const AccordionItem = ({ id, title, highlight, content }) => {
  return (
    <Accordion.Item value={id} className="py-8">
      <Accordion.Header>
        <Accordion.Trigger
          className="data-[state=closed]:saturate-0 data-[state=closed]:hover:saturate-100 
          data-[state=closed]:opacity-50 data-[state=closed]:hover:opacity-100 
          flex items-center gap-5 transition-all AccordionTrigger w-full text-left"
        >
          <span className="text-green-500 text-2xl -ml-8">&bull;</span>
          <h4 className="text-xl lg:text-3xl hover:text-blue transition">
            {title}
          </h4>
          <div className="flex-1"></div>
          <div className="AccordionChevron transition">
            <IconChevronDown />
          </div>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">
            <p className="text-xl leading-relaxed text-dark-grey py-8">
              <span className="text-lilac">{highlight} </span>&mdash; {content}
            </p>
          </div>
          <div className="flex-1"></div>
        </div>
      </Accordion.Content>
    </Accordion.Item>
  )
}

const ExecUseCase = () => (
  <div className="container mx-auto flex flex-col py-24">
    <h2 className="flex-1 text-4xl font-light text-lilac">
      Read every email and attend every meeting
    </h2>
    <p className="text-dark-grey max-w-2xl text-xl my-8">
      Your team, customers and investors send you notes and decks every day.
      Stay on top of the information overload and focus on the right problems to
      solve.
    </p>
    <div className="flex flex-col lg:flex-row gap-8 my-12">
      <div className="flex-1">
        <img
          src={foundersUseCase1}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconDatabase className="text-lilac mb-2" />
        <div className="font-bold my-4">Import docs and decks</div>
        <p className="text-dark-grey">
          Import presentations, contracts, decks, meeting notes &mdash; any sort
          of communication thrown your way.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={foundersUseCase2}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconTable className="text-lilac mb-2" />
        <div className="font-bold my-4">Find, summarize, prioritize</div>
        <p className="text-dark-grey">
          Surface contextual highlights and key answers relevant to your
          business, interpret the data, and then summarize next steps.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={foundersUseCase3}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconRocket className="text-lilac mb-2" />
        <div className="font-bold my-4">Lead with data</div>
        <p className="text-dark-grey">
          Share your analysis in an interactive reports with your team to drive
          the right priorities and focus your company.
        </p>
      </div>
    </div>
  </div>
)

const LegalUseCase = () => (
  <div className="container mx-auto flex flex-col py-24">
    <h2 className="flex-1 text-4xl font-light text-secondary-orange">
      Focus on your legal expertise, not your Office skills
    </h2>
    <p className="text-dark-grey max-w-2xl text-xl my-8">
      You manage a large caseload and each client demands breadth of knowledge
      at speed. Instead of sifting through documents, focus on giving great
      advice.
    </p>
    <div className="flex flex-col lg:flex-row gap-8 my-12">
      <div className="flex-1">
        <img
          src={legalUseCase1}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconDatabase className="text-lilac mb-2" />
        <div className="font-bold my-4">Connect client information</div>
        <p className="text-dark-grey">
          Import contracts, legislation, correspondence and meeting notes to
          easily access specific information
        </p>
      </div>
      <div className="flex-1">
        <img
          src={legalUseCase2}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconTable className="text-lilac mb-2" />
        <div className="font-bold my-4">Find, summarize, prioritize</div>
        <p className="text-dark-grey">
          Surface contextual information from different sources, ask questions
          about liabilities and risk, and summarize next steps
        </p>
      </div>
      <div className="flex-1">
        <img
          src={legalUseCase3}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconRocket className="text-lilac mb-2" />
        <div className="font-bold my-4">Collaborate effectively</div>
        <p className="text-dark-grey">
          Share your insights in interactive reports with your associates to
          improve client satisfaction
        </p>
      </div>
    </div>
  </div>
)

const InvestmentUseCase = () => (
  <div className="container mx-auto flex flex-col py-24">
    <h2 className="flex-1 text-4xl font-light text-secondary-teal">
      Build relationships and not investment memos
    </h2>
    <p className="text-dark-grey max-w-2xl text-xl my-8">
      Understand and segment your portfolio data and updates, so that you can
      personalise your advice and value to each one of them, maximising your
      returns.
    </p>
    <div className="flex flex-col lg:flex-row gap-8 my-12">
      <div className="flex-1">
        <img
          src={investorsUseCase1}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconDatabase className="text-lilac mb-2" />
        <div className="font-bold my-4">Connect portfolio data</div>
        <p className="text-dark-grey">
          Import and consolidate investment reports, pitch decks and key metrics
          for your entire investment portfolio
        </p>
      </div>
      <div className="flex-1">
        <img
          src={investorsUseCase2}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconTable className="text-lilac mb-2" />
        <div className="font-bold my-4">Find, summarize, prioritize</div>
        <p className="text-dark-grey">
          Surface contextual information from different investments, ask
          questions and summarize next steps
        </p>
      </div>
      <div className="flex-1">
        <img
          src={investorsUseCase3}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconRocket className="text-lilac mb-2" />
        <div className="font-bold my-4">Maximize returns</div>
        <p className="text-dark-grey">
          Display your insights in interactive reports with your associates to
          share learnings and boost portfolio performance
        </p>
      </div>
    </div>
  </div>
)

const SalesUseCase = () => (
  <div className="container mx-auto flex flex-col py-24">
    <h2 className="flex-1 text-4xl font-light text-secondary-light-blue">
      Increase revenue with contextual personalization
    </h2>
    <p className="text-dark-grey max-w-2xl text-xl my-8">
      Demand is drying up and you need to deliver revenue with less resources.
      Hone in on the right target market with better targeting, personalisation,
      and ICP.
    </p>
    <div className="flex flex-col lg:flex-row gap-8 my-12">
      <div className="flex-1">
        <img
          src={salesUseCase1}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconDatabase className="text-lilac mb-2" />
        <div className="font-bold my-4">Connect customer data</div>
        <p className="text-dark-grey">
          Connect your call recordings, CRM, people data, Google, job postings,
          annual reports, and more — anywhere you research your customers.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={salesUseCase2}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconTable className="text-lilac mb-2" />
        <div className="font-bold my-4">Find, summarize, prioritize</div>
        <p className="text-dark-grey">
          Extract relevant data points about your target accounts, prospects and
          customers to enrich and prioritise your focus.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={salesUseCase3}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconRocket className="text-lilac mb-2" />
        <div className="font-bold my-4">Increase revenue</div>
        <p className="text-dark-grey">
          Turn your analysis into TAM sizing, target account lists, AI
          prioritisation, sales triggers and deal reviews, integrated into your
          stack.
        </p>
      </div>
    </div>
  </div>
)

const ProcurementUseCase = () => (
  <div className="container mx-auto flex flex-col py-24">
    <h2 className="flex-1 text-4xl font-light text-secondary-red">
      Increase efficiency by removing manual data work
    </h2>
    <p className="text-dark-grey max-w-2xl text-xl my-8">
      Data is your competitive advantage, but it is costly to produce. You
      manage analysts who sifts over raw data every day. Scaling is a big
      challenge.
    </p>
    <div className="flex flex-col lg:flex-row gap-8 my-12">
      <div className="flex-1">
        <img
          src={procurementUseCase1}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconDatabase className="text-lilac mb-2" />
        <div className="font-bold my-4">Connect documents</div>
        <p className="text-dark-grey">
          Import PDFs, Word documents, and spreadsheets that are normally sent
          to human analysts.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={procurementUseCase2}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconTable className="text-lilac mb-2" />
        <div className="font-bold my-4">Find, summarize, prioritize</div>
        <p className="text-dark-grey">
          Replicate and automate the manual review process and use AI to extract
          key answers.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={procurementUseCase3}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconRocket className="text-lilac mb-2" />
        <div className="font-bold my-4">Do more with less</div>
        <p className="text-dark-grey">
          Deliver structured datasets to your business, and optimise your data
          building process over time.
        </p>
      </div>
    </div>
  </div>
)

const HRUseCase = () => (
  <div className="container mx-auto flex flex-col py-24">
    <h2 className="flex-1 text-4xl font-light text-secondary-dull-teal max-w-3xl">
      Hire the right talent by vetting and prioritizing candidates smarter
    </h2>
    <p className="text-dark-grey max-w-2xl text-xl my-8">
      Get to the right profile immediately with semantic search and automated
      analysis, saving you weeks in manually pouring through CVs.
    </p>
    <div className="flex flex-col lg:flex-row gap-8 my-12">
      <div className="flex-1">
        <img
          src={hrUseCase1}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconDatabase className="text-lilac mb-2" />
        <div className="font-bold my-4">Connect candidate data</div>
        <p className="text-dark-grey">
          Import CVs, application forms, and other interview material that are
          normally sent to recruiters.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={hrUseCase2}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconTable className="text-lilac mb-2" />
        <div className="font-bold my-4">Find, summarize, prioritize</div>
        <p className="text-dark-grey">
          Replicate and automate the manual review process and use AI to extract
          key answers, enabling recruiters to focus on speaking to candidates.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={hrUseCase3}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconRocket className="text-lilac mb-2" />
        <div className="font-bold my-4">Hire better and faster</div>
        <p className="text-dark-grey">
          Focus on promising candidates right away with a detailed understanding
          of their strengths and weaknesses.
        </p>
      </div>
    </div>
  </div>
)

const ResearcherUseCase = () => (
  <div className="container mx-auto flex flex-col py-24">
    <h2 className="flex-1 text-4xl font-light text-secondary-dark-blue max-w-3xl">
      Focus on industry knowledge and expertise, not copy and paste
    </h2>
    <p className="text-dark-grey max-w-2xl text-xl my-8">
      Generate distinctive insights and proprietary knowledge. Share and build
      insights, best practices and relevant new ideas.
    </p>
    <div className="flex flex-col lg:flex-row gap-8 my-12">
      <div className="flex-1">
        <img
          src={researcherUseCase1}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconDatabase className="text-lilac mb-2" />
        <div className="font-bold my-4">Connect raw data</div>
        <p className="text-dark-grey">
          Import industry, market, research data and financial information with
          references from public and private sources.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={researcherUseCase2}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconTable className="text-lilac mb-2" />
        <div className="font-bold my-4">Find, summarize, prioritize</div>
        <p className="text-dark-grey">
          Replicate and automate the manual research process and use AI to
          extract key answers, enabling you to focus on creating knowledge.
        </p>
      </div>
      <div className="flex-1">
        <img
          src={researcherUseCase3}
          className="mb-6 rounded-lg border-2 border-medium-grey shadow-md"
        ></img>
        <IconRocket className="text-lilac mb-2" />
        <div className="font-bold my-4">Generate unique insight</div>
        <p className="text-dark-grey">
          Save time generating unique conclusions and sharing them with your
          stakeholders and customers.
        </p>
      </div>
    </div>
  </div>
)

const TacticNext = () => {
  const [useCase, setUseCase] = useState("execs")

  return (
    <Layout variant="useCase">
      <SEO
        title="Generate"
        description="Generate insights from any document, anywhere"
        image={SEOimage}
      />

      <section>
        <div className="container mx-auto lg:my-24 px-4 text-white">
          <div className="xl:my-24">
            <div className="md:text-center my-24">
              <h1
                className="text-5xl md:text-6xl lg:text-8xl font-semibold my-8"
                style={{
                  mixBlendMode: "overlay",
                  fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
                }}
              >
                Generate insights from any document, anywhere
              </h1>
              <p className="text-lg max-w-4xl mx-auto text-white/75 my-8">
                Save time and make smarter decisions by automating research,
                analysis and action with Tactic.
              </p>
              <a
                href="https://app.tactic.fyi/generate?screen_hint=signup"
                className="rounded-lg bg-lilac text-white hover:bg-lilac-dark transition
              shadow px-8 py-3 text-xl"
              >
                Sign up for free
              </a>
              <div className="text-xs font-sans my-6 text-dark-grey">
                Beta product &middot; Free until launch
              </div>
            </div>
            <div className="w-full xl:flex-1 rounded-lg overflow-hidden shadow-2xl shadow-lilac">
              <div className="relative pb-[56.25%] h-0">
                <iframe
                  src="https://www.loom.com/embed/0e83b96e9e0442349256b8d6e90bc97b?sid=cf3ddf50-85d1-4def-9774-33c954ec397a?hide_title=true&hideEmbedTopBar=true"
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="container mx-auto flex">
            <div className="flex-1"></div>
            <LogoWall />
            <div className="flex-1"></div>
          </div>
        </div>
      </section>

      <section className="bg-white pt-24">
        <div className="container mx-auto flex flex-col px-2">
          <div className="max-w-3xl mx-auto text-center">
            <div className="text-dark-grey">Use cases</div>
            <div className="text-5xl font-semibold my-6">
              Designed for people who have too much to read
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-8 py-8 justify-center border-b">
            <div
              className={classNames("cursor-pointer hover:text-lilac", {
                "text-lilac border-b border-lilac-40": useCase == "execs",
              })}
              onClick={() => setUseCase("execs")}
            >
              Executives
            </div>
            <div
              className={classNames("cursor-pointer hover:text-lilac", {
                "text-lilac border-b border-lilac-40": useCase == "researcher",
              })}
              onClick={() => setUseCase("researcher")}
            >
              Research Analysts
            </div>
            <div
              className={classNames("cursor-pointer hover:text-lilac", {
                "text-lilac border-b border-lilac-40": useCase == "legal",
              })}
              onClick={() => setUseCase("legal")}
            >
              Legal
            </div>
            <div
              className={classNames("cursor-pointer hover:text-lilac", {
                "text-lilac border-b border-lilac-40": useCase == "investors",
              })}
              onClick={() => setUseCase("investors")}
            >
              Investors
            </div>
            <div
              className={classNames("cursor-pointer hover:text-lilac", {
                "text-lilac border-b border-lilac-40": useCase == "sales",
              })}
              onClick={() => setUseCase("sales")}
            >
              Sales & marketing
            </div>
            <div
              className={classNames("cursor-pointer hover:text-lilac", {
                "text-lilac border-b border-lilac-40": useCase == "procurement",
              })}
              onClick={() => setUseCase("procurement")}
            >
              Procurement
            </div>
            <div
              className={classNames("cursor-pointer hover:text-lilac", {
                "text-lilac border-b border-lilac-40": useCase == "hr",
              })}
              onClick={() => setUseCase("hr")}
            >
              HR
            </div>
          </div>
          {useCase === "execs" && <ExecUseCase />}
          {useCase === "researcher" && <ResearcherUseCase />}
          {useCase === "legal" && <LegalUseCase />}
          {useCase === "investors" && <InvestmentUseCase />}
          {useCase === "sales" && <SalesUseCase />}
          {useCase === "procurement" && <ProcurementUseCase />}
          {useCase === "hr" && <HRUseCase />}
        </div>
      </section>

      {/* information gathering, cross-referencing, summarizing, composing, designing, feedback, publishing */}
      <section className="bg-white">
        <div className="container mx-auto flex flex-col gap-12 px-2">
          {/* information gathering, answer types */}
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="flex-1 bg-light-grey rounded-xl relative min-h-[600px]">
              <div className="p-12">
                <div className="text-3xl font-semibold mb-4">
                  Import all of your unstructured documents
                </div>
                <p className="text-dark-grey">
                  Use Tactic projects to organise and analyse text data from any
                  source, including news, Google, PDFs, webpages, APIs, customer
                  conversations, product feedback, market research, and more.
                </p>
              </div>
              <img src={importDataSVG} className="absolute bottom-0 right-0" />
            </div>

            <div className="flex-1 bg-light-grey rounded-xl">
              <img src={rightAnswerSVG} className="w-full mb-4" />
              <div className="p-12">
                <div className="text-3xl font-semibold mb-4">
                  Get the right answers, in the right format
                </div>
                <p className="text-dark-grey">
                  Powerful ways to ask specific questions and get formatted
                  answers. Think SQL for unstructured data.
                </p>
              </div>
            </div>
          </div>

          {/* cross-referencing, summarizing, composing + designing */}
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="flex-1 flex flex-col justify-between bg-light-grey rounded-xl">
              <img src={crossRefPng} className="w-full my-8 p-4" />
              <div className="p-6 text-center">
                <div className="text-xl font-semibold mb-4">
                  Cross reference multiple documents
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-between bg-light-grey rounded-xl">
              <img src={summarizePng} className="w-full mb-4 pl-8" />
              <div className="p-6 text-center">
                <div className="text-xl font-semibold mb-4">
                  Summarise your findings
                </div>
              </div>
            </div>
            <div className="flex-1 bg-light-grey rounded-xl relative overflow-hidden">
              <div className="px-10 mb-6 pt-56 z-10 absolute bottom-0 bg-gradient-to-b from-transparent to-light-grey w-full">
                <div className="text-xl font-semibold">
                  Design the perfect report
                </div>
                <p className="text-dark-grey">
                  Markdown support, just like Notion.
                </p>
              </div>
              <img src={designPng} className="w-full mb-4 absolute" />
            </div>
          </div>

          {/* feedback, publishing */}
          <div className="flex flex-col lg:flex-row gap-12">
            <div className="flex-1 bg-light-grey rounded-xl p-6">
              <div className="max-w-4xl mx-auto text-center my-8">
                <div className="text-4xl font-semibold mb-4">
                  Share and publish your work
                </div>
                <p className="text-dark-grey">
                  Click a button, and transform your notebook into beautifully
                  designed tables and reports, so that you can show off your
                  work and drive action in your team.
                </p>
              </div>
              <img
                src={sharingViewPng}
                className="border-medium-grey border rounded-lg shadow-container"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-24">
        <div className="container mx-auto px-2">
          <div className="max-w-3xl mx-auto">
            <div className="text-5xl font-semibold my-12">
              One tool for your whole company. Free for teams to try.
            </div>
          </div>
          <div className="flex flex-col md:flex-row rounded-xl border border-medium-grey divide-x divide-medium-grey">
            <div className="p-6 flex-1">
              <div className="font-bold text-amber my-4">Free</div>
              <div className="font-bold text-4xl">Free</div>
              <div className="text-dark-grey">per user / month</div>
              <div className="my-4">
                <a
                  href="https://app.tactic.fyi/generate?screen_hint=signup"
                  className="rounded-lg bg-white hover:border-blue border border-medium-grey transition
                shadow px-4 py-1.5 hover:text-blue"
                >
                  Get started
                </a>
              </div>
              <ul className="text-dark-grey my-8">
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Import 5 documents
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Build 1 visual
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Each visual can have up to 5 questions
                </li>
              </ul>
            </div>
            <div className="p-6 flex-1">
              <div className="font-bold text-blue my-4">Starter</div>
              <div className="font-bold text-4xl">$20</div>
              <div className="text-dark-grey">per user / month</div>
              <div className="my-4">
                <a
                  href="https://app.tactic.fyi/generate?screen_hint=signup"
                  className="rounded-lg bg-lilac text-white hover:bg-lilac-dark transition
              shadow px-4 py-1.5"
                >
                  Get started
                </a>
                <div className="text-xs font-sans my-6 text-dark-grey">
                  Beta product &middot; Free until launch
                </div>
              </div>
              <ul className="text-dark-grey my-8">
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Import 100 documents
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Build 5 visuals
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Each visual can have up to 20 questions
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />1
                  integration
                </li>
              </ul>
            </div>
            <div className="p-6 flex-1">
              <div className="font-bold text-secondary-red my-4">Business</div>
              <div className="font-bold text-4xl">$70</div>
              <div className="text-dark-grey">per user / month</div>
              <div className="my-4">
                <a
                  href="https://app.tactic.fyi/generate?screen_hint=signup"
                  className="rounded-lg bg-white hover:border-blue border border-medium-grey transition
                shadow px-4 py-1.5 hover:text-blue"
                >
                  Get started
                </a>
                <div className="text-xs font-sans my-6 text-dark-grey">
                  Beta product &middot; Free until launch
                </div>
              </div>
              <ul className="text-dark-grey my-8">
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Unlimited documents
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Build 20 visuals
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Each visual can have up to 20 questions
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />3
                  integrations
                </li>
              </ul>
            </div>
            <div className="p-6 flex-1">
              <div className="font-bold text-secondary-purple my-4">
                Enterprise
              </div>
              <div className="font-bold text-4xl">Speak to sales</div>
              <div className="text-white">Request demo</div>
              <div className="my-4">
                <a
                  href="/win-with-tactic"
                  className="rounded-lg bg-white hover:border-blue border border-medium-grey transition
                shadow px-4 py-1.5 hover:text-blue"
                >
                  Request a demo
                </a>
              </div>
              <ul className="text-dark-grey my-8">
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Unlimited documents
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Unlimited visuals
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Unlimited questions
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Bring your own AI
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Bring your own database
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Advanced security and controls
                </li>
                <li>
                  <IconCheck size={16} className="inline mr-1 -mt-1" />
                  Customer success manager
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto px-2">
          <div className="max-w-4xl mx-auto text-center">
            <div className="text-4xl font-semibold py-12">
              Frequently asked questions (FAQ)
            </div>
          </div>
          <Accordion.Root
            type="single"
            defaultValue="item-1"
            className="divide-y divide-dark-grey/25 flex-1"
          >
            <AccordionItem
              id="item-1"
              title="Why can't I just do this with ChatGPT?"
              highlight="ChatGPT is a chatbot, Tactic is an AI app builder"
              content="Tactic adds end-to-end features like importing data, extracting structure from unstructured information, visualising it, and integrations."
            />
            <AccordionItem
              id="item-2"
              title="Isn't this just copy and pasting text into GPT?"
              highlight="Tactic enables you to import 20 different document formats at 20,000 documents per hour"
              content="with ChatGPT, you can't import and sync reams of documents, from different formats automatically, you'll have to manually open each one, then copy & paste."
            />
            <AccordionItem
              id="item-3"
              title="What kinds of questions can this answer?"
              highlight="Tactic helps you ask questions in 5 different ways"
              content="ChatGPT supports asking questions with natural language, Tactic adds on the ability to find lookalike topics (eg. lookalike customers) and perform exact keyword and code search."
            />
            <AccordionItem
              id="item-4"
              title="What is a Tactic visual?"
              highlight="A picture tells a thousand words"
              content="ChatGPT gives you back, well, chats; Tactic adds tables, datasets, reports, kanban boards, and charts to visualise your data better."
            />
            <AccordionItem
              id="item-5"
              title="What happens after I visualise the answers?"
              highlight="Tactic is user friendly and enterprise ready"
              content="Tactic aims to integrate AI into businesses systems like Salesforce, Snowflake and ServiceNow; There is no 'do X on Salesforce' button in ChatGPT."
            />
            <AccordionItem
              id="item-6"
              title="How secure is my data?"
              highlight="Tactic is private by design"
              content="We do not pool user data to train our AI models. We don't have access to your data, only you do."
            />
            <AccordionItem
              id="item-7"
              title="What are your data security measures?"
              highlight="As industry veterans, we know it all comes down to security"
              content="Tactic uses AES-256 encryption. We are SOC II certified and world class security protocols to keep your data safe both at rest and in transit."
            />
          </Accordion.Root>
        </div>
      </section>

      <section className="bg-white py-24">
        <div className="container mx-auto text-center px-2 rounded-xl bg-blue-10 py-24">
          <h1 className="text-4xl lg:text-6xl font-semibold mx-auto">
            Get started for free
          </h1>
          <p className="text-lg lg:text-2xl text-dark-grey max-w-4xl mx-auto my-12">
            Play around with it first. Pay and add your team later.
          </p>
          <div className="flex justify-center">
            <a
              href="https://app.tactic.fyi/generate?screen_hint=signup"
              className="rounded-lg bg-lilac text-white hover:bg-lilac-dark transition
          shadow px-8 py-3 text-xl"
            >
              Try Tactic &rarr;
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TacticNext
